import { render, staticRenderFns } from "./ExampleList.vue?vue&type=template&id=508d1a58&scoped=true&"
import script from "./ExampleList.vue?vue&type=script&lang=js&"
export * from "./ExampleList.vue?vue&type=script&lang=js&"
import style0 from "./ExampleList.vue?vue&type=style&index=0&id=508d1a58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508d1a58",
  null
  
)

export default component.exports